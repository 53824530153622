export const formatDate = (dateString, time = false) => {
  const date = new Date(dateString);
  let day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }

  let month = date.getMonth();
  if (month < 10) {
    month = "0" + month;
  }

  let year = date.getFullYear();

  let timeStr =
    date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

  let formatted = day + "-" + month + "-" + year;
  if (time) {
    formatted += " " + timeStr;
  }
  return formatted;
};

export const getDateOnlyWithoutTime = (dateString) => {
  const date = new Date(dateString);
  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0");
  let year = date.getFullYear();

  let formatted = `${day}-${month}-${year}`;
  return formatted;
};
