import { gql } from "@apollo/client";

export const CREATE_TRANSACTION_SELL = gql`
  mutation (
    $branch_id: Int!
    $buyer_id: Int!
    $courier_id: Int!
    $tv_id: Int!
    $shipping_cost: Int
    $address: String
    $province: String
    $district: String
    $sub_district: String
    $phone_number: String!
    $postal_code: String
    $inventories: CreateInventoriesRelation
    $coupon_id: Int
  ) {
    sellTransaction(
      input: {
        branch_id: $branch_id
        buyer_id: $buyer_id
        courier_id: $courier_id
        tv_id: $tv_id
        shipping_cost: $shipping_cost
        address: $address
        province: $province
        district: $district
        sub_district: $sub_district
        postal_code: $postal_code
        phone_number: $phone_number
        inventories: $inventories
        coupon_id: $coupon_id
      }
    ) {
      response {
        status
        message
      }
      transaction {
        id
        invoice_number
        status
      }
    }
  }
`;

export const CREATE_TRANSACTION_BUY_TO_SUPPLIER = gql`
  mutation (
    $supplier_id: Int!
    $courier_id: Int!
    $description: String
    $address: String
    $province: String
    $district: String
    $sub_district: String
    $postal_code: String
    $inventories: [CreateInventoriesInput!]!
  ) {
    buyToSupplier(
      input: {
        supplier_id: $supplier_id
        courier_id: $courier_id
        description: $description
        address: $address
        province: $province
        district: $district
        sub_district: $sub_district
        postal_code: $postal_code
        inventories: { create: $inventories }
      }
    ) {
      status
      message
    }
  }
`;

export const CREATE_TRANSACTION_BUY_TO_HQ = gql`
  mutation ($description: String, $inventories: [CreateInventoriesInput!]!) {
    buyToHQ(
      input: {
        description: $description
        inventories: { create: $inventories }
      }
    ) {
      status
      message
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation (
    $id: ID!
    $status: TransactionStatus!
    $courier_id: Int
    $adjustment: Int
    $shipping_cost_per_kg: Int
    $description: String
    $source: Source
  ) {
    updateTransaction(
      id: $id
      status: $status
      courier_id: $courier_id
      adjustment: $adjustment
      shipping_cost_per_kg: $shipping_cost_per_kg
      description: $description
      source: $source
    ) {
      transaction {
        id
        invoice_number
      }
    }
  }
`;

export const CANCEL_TRANSACTION = gql`
  mutation ($id: ID!, $status: TransactionStatus!, $reason: String!) {
    CancelTransaction(id: $id, status: $status, reason: $reason) {
      response {
        status
        message
      }
      transaction {
        id
        invoice_number
        status
      }
    }
  }
`;

export const REVERT_CANCELLED_TRANSACTION_TO_PENDING = gql`
  mutation ($id: ID!) {
    RevertCancelledTransactionToPending(id: $id) {
      response {
        status
        message
      }
      transaction {
        id
        invoice_number
        status
      }
    }
  }
`;

export const DONE_TRANSACTION = gql`
  mutation (
    $id: ID!
    $status: TransactionStatus!
    $additional_fee: Int
    $shipping_cost: Int
    $courier_id: Int
  ) {
    DoneTransaction(
      id: $id
      status: $status
      additional_fee: $additional_fee
      shipping_cost: $shipping_cost
      courier_id: $courier_id
    ) {
      response {
        status
        message
      }
      transaction {
        id
        invoice_number
        status
      }
    }
  }
`;

export const REVERT_DONE_TRANSACTION_TO_PENDING = gql`
  mutation ($id: ID!) {
    RevertDoneTransactionToPending(id: $id) {
      response {
        status
        message
      }
      transaction {
        id
        invoice_number
        status
      }
    }
  }
`;

export const ADD_COMMISION_TO_TRANSACTION = gql`
  mutation ($id: ID!, $status: TransactionStatus!, $commision: Int!) {
    AddCommisionToTransaction(id: $id, status: $status, commision: $commision) {
      response {
        status
        message
      }
      transaction {
        id
        invoice_number
        status
        commision
      }
    }
  }
`;

export const CLOSE_TRANSACTION = gql`
  mutation ($id: ID!, $status: TransactionStatus!) {
    ClosedTransaction(id: $id, status: $status) {
      response {
        status
        message
      }
      transaction {
        id
        invoice_number
        status
        commision
      }
    }
  }
`;
