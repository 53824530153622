import React, { useState } from "react";
import { PageHeader } from "@ant-design/pro-components";
import { TRANSACTIONS_SELL } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";
import { Table, Tag, Form, Input, Button } from "antd";
import { Link } from "react-router-dom";
import Locations from "Helpers/Locations";
import StatusSelect from "UI/Input/StatusSelect";
import BranchSelect from "UI/Input/BranchSelect";
import DateSelect from "UI/Input/DateSelect";
import DomOutput from "Helpers/DomOutput";
import { useSelector } from "react-redux";
import { FileAddOutlined } from "@ant-design/icons";
import { getDateOnlyWithoutTime } from "Utils/Date";
const List = (props) => {
  const me = useSelector((state) => state.me);
  const permissions = useSelector((state) => state.permissions);
  const [state, setState] = useState({
    pageNumber: 1,
    keyword: "",
    status: null,
    branch_id: null,
    anytime: true,
    dateRangeOpen: false,
    startDate: null,
    endDate: null,
  });

  const {
    pageNumber,
    keyword,
    status,
    branch_id,
    anytime,
    dateRangeOpen,
    startDate,
    endDate,
  } = state;

  const {
    data: transactionData,
    error,
    loading,
  } = useQuery(TRANSACTIONS_SELL, {
    fetchPolicy: "cache-and-network",
    variables: {
      count: 10,
      page: pageNumber,
      keyword,
      status,
      seller_id: branch_id,
      start_date: anytime ? null : startDate.format("YYYY-MM-DD"),
      end_date: anytime ? null : endDate.format("YYYY-MM-DD"),
    },
  });

  let pageTotal = 0;
  if (!loading) {
    pageTotal = transactionData.transactionListSell.paginationInfo.total;
  }
  const columns = [
    {
      title: "INV",
      dataIndex: "invoice_number",
      key: "invoice_number",
      render: (text, record) => (
        <span>
          <Link to={`/transactions/${record.id}/view`}>
            {record.invoice_number}
          </Link>{" "}
          <Tag
            color={
              record.status === "PENDING"
                ? "grey"
                : record.status === "CANCELLED"
                ? "red"
                : "green"
            }
          >
            {record.status}
          </Tag>
          <br />
          {record.agent.name}@{record.seller.name}
          <br />
          on {new Date(record.created_at).toDateString()} (
          {new Date(record.created_at).getHours()}:
          {new Date(record.created_at).getMinutes()})
          {record.status === "DONE"
                             ? <span><br /> {record.settled ? <Tag color="green">Settled</Tag> : <Tag>Pending settlement on: {getDateOnlyWithoutTime(record.max_settle_date)}</Tag>}</span> :""}
        </span>
      ),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text, record) => (
        <span>
          {record.buyer.name}
          <br />
          {record.sub_district}
          <br />
          {Locations.getCaption(record.district)},{" "}
          {Locations.getCaption(record.province)}
          <br />
          <a
            href={`phone:${
              record.phone_number
                ? record.phone_number
                : record.buyer.phone_number
            }`}
          >
            {record.phone_number
              ? record.phone_number
              : `*${record.buyer.phone_number}`}
          </a>
          {record.buyer.phone_number_2 && (
            <>
              {" "}
              /{" "}
              <a href={`phone:${record.buyer.phone_number_2}`}>
                {record.buyer.phone_number_2}
              </a>
            </>
          )}
        </span>
      ),
    },

    {
      title: "Grandtotal",
      dataIndex: "grandtotal",
      align: "right",
      key: "grandtotal",
      render: (text, record) => (
        <span>{window.idCurrency.format(record.grandtotal)}</span>
      ),
    },
  ];
  return (
    <React.Fragment>
      <PageHeader
        title="Transactions"
        extra={
          permissions.CAN_CREATE_TRANSACTION_SELL &&
          me.branch.id === 1 && [
            <Link to="/transactions/add" key="add">
              <Button type="primary" icon={<FileAddOutlined />}>
                {" "}
                Add
              </Button>
            </Link>,
          ]
        }
      />
      <Form layout="inline">
        <Form.Item label="Search">
          <Input
            allowClear
            placeholder="eg. Blender"
            onPressEnter={(e) => {
              setState({
                ...state,
                keyword: e.target.value,
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Branch">
          <BranchSelect
            defaultValue="all"
            onChange={(value) => {
              if (value === "all") {
                setState({
                  ...state,
                  branch_id: null,
                  pageNumber: 1,
                });
              } else {
                setState({
                  ...state,
                  branch_id: value,
                  pageNumber: 1,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item label="Status">
          <StatusSelect
            defaultValue="ALL"
            onChange={(value) => {
              if (value === "ALL") {
                setState({
                  ...state,
                  status: null,
                  pageNumber: 1,
                });
              } else {
                setState({
                  ...state,
                  status: value,
                  pageNumber: 1,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item label="Created on">
          <Button
            onClick={(e) => {
              setState({
                ...state,
                dateRangeOpen: !dateRangeOpen,
              });
            }}
          >
            {anytime
              ? "Anytime"
              : `${startDate.format("DD/MM/YYYY")} to ${endDate.format(
                  "DD/MM/YYYY"
                )}`}
          </Button>
          <DateSelect
            anytime={anytime}
            onCancel={() => {
              setState({
                ...state,
                dateRangeOpen: false,
              });
            }}
            submit={(v) => {
              console.log(v);
              setState({
                ...state,
                dateRangeOpen: false,
                anytime: v.anytime,
                startDate: v.range[0],
                endDate: v.range[1],
                pageNumber: 1,
              });
            }}
            visible={dateRangeOpen}
          />
        </Form.Item>
      </Form>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={
          loading
            ? []
            : transactionData.transactionListSell.data
            ? transactionData.transactionListSell.data
            : []
        }
        loading={loading}
        pagination={{
          total: pageTotal,
          onChange: (page, pageSize) =>
            setState({ ...state, pageNumber: page }),
          current: pageNumber,
        }}
      />
      <DomOutput data={state} />
    </React.Fragment>
  );
};

export default List;
