import { gql } from "@apollo/client";

export const TRANSACTIONS_SELL = gql`
  query (
    $filter_me: Boolean
    $buyer_id: Int
    $seller_id: Int
    $agent_id: Int
    $courier_id: Int
    $tv_id: Int
    $status: TransactionStatus
    $keyword: String
    $product_id: Int
    $start_date: Date
    $end_date: Date
    $page: Int!
    $count: Int!
    $province: String
    $district: String
    $sub_district: String
    $settled: Boolean
  ) {
    transactionListSell(
      filter_me: $filter_me
      buyer_id: $buyer_id
      seller_id: $seller_id
      agent_id: $agent_id
      courier_id: $courier_id
      tv_id: $tv_id
      status: $status
      keyword: $keyword
      product_id: $product_id
      start_date: $start_date
      end_date: $end_date
      province: $province
      district: $district
      sub_district: $sub_district
      page: $page
      count: $count
      settled: $settled
    ) {
      paginationInfo {
        total
        lastPage
      }
      data {
        id
        invoice_number
        seller_id
        buyer_id
        agent_id
        tv_id
        courier_id
        shipping_cost
        additional_fee
        status
        grandtotal
        price_total
        created_at
        updated_at
        description
        commision
        province
        district
        sub_district
        postal_code
        phone_number
        settled
        max_settle_date
        seller {
          id
          email
          name
          branch {
            id
            name
          }
        }
        buyer {
          id
          name
          email
          phone_number
          phone_number_2
          address
          province
          district
          sub_district
          postal_code
          created_at
          updated_at
        }
        agent {
          id
          name
        }
        courier {
          id
          name
        }
        tv {
          id
          name
        }
        inventories {
          id
          quantity
          price
          product {
            id
            category_id
            name
            weight
            price
            description
            is_active
            stock
            minqty
            created_at
            updated_at
          }
          created_at
          updated_at
        }
      }
    }
  }
`;

export const TRANSACTIONS_BUY = gql`
  query ($page: Int!, $count: Int!, $keyword: String) {
    transactionListBuy(page: $page, count: $count, keyword: $keyword) {
      paginationInfo {
        total
        lastPage
      }
      data {
        id
        invoice_number
        status
        seller {
          id
          name
        }
        buyer {
          id
          name
        }
        agent {
          id
          name
        }
        created_at
        grandtotal
      }
    }
  }
`;

export const TRANSACTION = gql`
  query ($id: ID!) {
    transaction(id: $id) {
      id
      invoice_number
      status
      grandtotal
      price_total
      created_at
      updated_at
      description
      commision
      seller_id
      buyer_id
      agent_id
      courier_id
      province
      district
      shipping_cost
      additional_fee
      commision
      address
      phone_number
      sub_district
      settled
      max_settle_date
      coupon {
        id
        name
        key
        type
        amount_type
        amount
        start_period
        end_period
        description
        enable
        is_expired
        created_at
        updated_at
      }
      tv {
        id
        name
      }
      seller {
        id
        name
      }
      buyer {
        id
        name
        phone_number
        phone_number_2
      }
      agent {
        id
        name
        branch {
          id
          name
        }
      }
      courier {
        id
        name
      }
      inventories {
        id
        product {
          id
          name
          price
        }
        quantity
        price
      }
    }
  }
`;

export const TRANSACTION_LOG = gql`
  query ($transaction_id: Int) {
    transactionsLog(transaction_id: $transaction_id, page: 1, count: 999) {
      paginationInfo {
        total
        lastPage
      }
      data {
        id
        agent {
          id
          name
          branch {
            id
            name
          }
        }
        before_status
        after_status
        created_at
      }
    }
  }
`;

export const TRANSACTION_COUNT = gql`
  query ($status: TransactionStatus, $branch_id: Int) {
    transactionCount(status: $status, branch_id: $branch_id) {
      count
      status
      branch_id
    }
  }
`;
